const languages = ["en", "ru", "hy"]

const findLanguage = () => {
    const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
    return languages.includes(lang) ? lang : "hy";
}

let container, scriptsLoaded, primaryColor, elem, widgetEvent

const loadScripts = (source) => {
    const uri = `${window.VivaMtsWidget.HOST}${source}`;
    if (source.includes('.css')) {
        const script = document.createElement('link');
        scriptsLoaded.push(true);
        script.href = uri;
        script.rel = 'stylesheet';
        script.type = 'text/css';
        document.head.appendChild(script)
    } else {
        const script = document.createElement('script');
        script.onload = () => {
            scriptsLoaded.push(true);
            elem.dispatchEvent(widgetEvent);
        }
        script.src = uri;
        document.head.appendChild(script)
    }
}

const getManifest = async () => {
    const uri = `${window.VivaMtsWidget.HOST}${window.VivaMtsWidget.manifestSource}`
    const response = await fetch(uri);
    const manifest = await response.json();
    for (const file of manifest.entrypoints) {
        loadScripts(file)
        window.VivaMtsWidget.manifestFiles.push(file);
    }
}

const initContainer = () => {
    let element = document.getElementById("viva_mts_widget");
    if (!element) {
        element = document.createElement("div")
        element.setAttribute("id", container);
        element.setAttribute("class", "vmw_container");

        const style = document.createElement('style');
        style.setAttribute("type", "text/css")
        style.innerHTML = `.vmw_container {
                position: fixed;
                width:0;
                height:0;
                z-index:1000000;
                right:0;
                bottom:0
            }
            #viva_mts_widget {
                margin: 0;
                padding: 0;
                font-family: "Source Sans Pro",sans-serif !important;
            }
            :root {
              --primary-color: ${primaryColor};
            }
            `;
        document.body.appendChild(style);
        document.body.appendChild(element)
        elem = element;
    }
}

const scriptsCompleteLoad = () => {
    return new Promise(resolve => {
        elem.addEventListener("manifestLoaded", () => {
            if (scriptsLoaded.length === window.VivaMtsWidget.manifestFiles.length) {
                return resolve(true);
            }
        })
    })
}

const setup = async (props) => {
    const origin = document.currentScript.getAttribute("origin");
    const PRIMARY_COLOR = '#ED1C24'
    container = props.container || "viva_mts_widget"
    scriptsLoaded = [];
    primaryColor = props.primaryColor || PRIMARY_COLOR;
    window.VivaMtsWidget = {
        HOST: origin,
        service: props.service || '',
        origin,
        manifestSource: "asset-manifest.json",
        manifestFiles: [],
        language: props.language || findLanguage()
    };
    widgetEvent = document.createEvent("event");
    widgetEvent.initEvent("manifestLoaded", true, true)
    initContainer();
    await getManifest();
    await scriptsCompleteLoad();
    window.VivaMtsWidget.renderer(container)
}

const VivaMtsWidget = {
    initContainer: initContainer,

    loadScripts: loadScripts,

    getManifest: getManifest,

    scriptsCompleteLoad: scriptsCompleteLoad,

    init: (props) => {
        if (navigator.userAgent.match(/msie/i) || navigator.userAgent.match(/trident/i)) {
            const lang = document.getElementsByTagName('html')[0].getAttribute('lang')
            const tag = document.createElement("div");
            tag.onclick = function () {
                if (lang === 'en') {
                    alert('Live chat is not supported on the Internet Explorer. Please use another browser or call +37493297111')
                } else if (lang === 'hy') {
                    alert('Internet Explorer-ով հնարավոր չէ հաղորդագրություններ ուղարկել օպերատորներին։ Խնդրում ենք օգտագործել այլ  բրաուզեր, կամ զանգահարել +37493297111')
                } else {
                    alert('Ваш браузер не поддерживает возможность общения с оператором. Пожалуйста используйте другой браузер или позвоните +37493297111')
                }
            }
            tag.className = 'ie_container'
            const style = document.createElement('style');
            style.setAttribute("type", "text/css")
            style.innerHTML = `.ie_container {
                width: 60px;
                height: 60px;
                position: absolute;
                bottom: 34px;
                right: 34px;
                border-radius: 50%;
                background: url("./ie_btn.png") no-repeat center;
                background-size: contain;
                cursor: pointer;
            }
            `;
            const element = document.getElementsByTagName("body")[0];
            element.appendChild(style)
            return element.appendChild(tag);
        }
        return setup(props)
    }
}

if (!window.VivaMtsWidget) {
    window.VivaMtsWidget = VivaMtsWidget;
}
